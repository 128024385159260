const CATEGORY = {
  ME: 'me',
  SHARED_FOCUS: 'sharedFocus',
  WE: 'we',
  SUPPORT: 'support',
  AMENITY: 'amenity',
  CUSTOM_AMENITY: 'customAmenity'
};

const CIRCULATION_TYPE = {
  ENCLOSED: 'enclosed',
  OPEN: 'open',
  BLENDED: 'blended',
  AMENITY: 'amenity'
};

const UNIT_LABEL = {
  SEATS: 'seats',
  ROOMS: 'rooms',
  AREAS: 'areas',
  UNITS: 'units',
  DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT: 'areas' // by default, show "areas", cause that's the most generic
};

const REQUIRED_FIELDS = {
  ME: ['circulationType', 'sf'],
  SHARED_FOCUS: ['circulationType', 'seats', 'sf'],
  WE: ['circulationType', 'seats', 'sf'],
  SUPPORT: ['circulationType', 'multiplier', 'sf'],
  AMENITY: ['circulationType', 'multiplier']
};

const CATEGORY_LIST = [CATEGORY.ME, CATEGORY.SHARED_FOCUS, CATEGORY.WE, CATEGORY.SUPPORT, CATEGORY.AMENITY];

const STANDARD_CIRCULATION_MAP = {
  [CIRCULATION_TYPE.ENCLOSED]: 0.25,
  [CIRCULATION_TYPE.OPEN]: 0.45,
  [CIRCULATION_TYPE.AMENITY]: 0.35
};

const HIGH_CIRCULATION_MAP = {
  [CIRCULATION_TYPE.ENCLOSED]: 0.30,
  [CIRCULATION_TYPE.OPEN]: 0.50,
  [CIRCULATION_TYPE.AMENITY]: 0.35
};

const APAC_CIRCULATION_MAP = {
  [CIRCULATION_TYPE.ENCLOSED]: 0.3,
  [CIRCULATION_TYPE.OPEN]: 0.3,
  [CIRCULATION_TYPE.AMENITY]: 0.3
};

//currently all countries have same circulation factor. Here you can add more countries
const COUNTRIES_CIRCULATION_MAP = {
  "FRA": {
    STANDARD_CIRCULATION_MAP: {
      [CIRCULATION_TYPE.ENCLOSED]: 0.25,
      [CIRCULATION_TYPE.OPEN]: 0.45,
      [CIRCULATION_TYPE.AMENITY]: 0.35
    },
    HIGH_CIRCULATION_MAP: {
      [CIRCULATION_TYPE.ENCLOSED]: 0.30,
      [CIRCULATION_TYPE.OPEN]: 0.50,
      [CIRCULATION_TYPE.AMENITY]: 0.35
    }
  }
};

module.exports = {
  CATEGORY,
  CIRCULATION_TYPE,
  UNIT_LABEL,
  CATEGORY_LIST,
  REQUIRED_FIELDS,
  STANDARD_CIRCULATION_MAP,
  HIGH_CIRCULATION_MAP,
  APAC_CIRCULATION_MAP,
  COUNTRIES_CIRCULATION_MAP
};
