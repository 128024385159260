module.exports = {
  FLOORPLAN_TYPE: {
    PARTIAL_5K: 'partial_5K',
    FULL_5K: 'full_5k',
    PARTIAL_10K: 'partial_10k',
    FULL_10K: 'full_10k',
    PARTIAL_15K: 'partial_15k',
    FULL_15K: 'full_15k',
    PARTIAL_20K: 'partial_20k',
    FULL_20K: 'full_20k'
  }
};
