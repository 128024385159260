module.exports = {

  TYPES: {
    NONE: 'none',
    FORM: 'form',
    CONFIRMATION: 'confirmation'
  },

  FORM_TYPES: {
    FIND_SPACE: 'find space'
  }

};
