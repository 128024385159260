const modal = require('./modal');

module.exports = {

  SPACER_SUPPORT_EMAIL: process.env.SUPPORT_EMAIL || 'spacersupport@cbre.com',

  SPACER_SUPPORT_CONTACT_TYPES: {
    SAVE_OTH: 'save',
    SAVE_EMEA: 'save_emea',
    SAVE_APAC: 'save_apac',
    SAVE_US: 'save_us',
    FIND_SPACE: modal.FORM_TYPES.FIND_SPACE
  }

};
