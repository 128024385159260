module.exports = {

  COLLABORATION_LEVEL: {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3
  },

  DENSITY_LEVEL: {
    SAME: 1,
    LOW: 2,
    NOT_SURE: 3
  },

  SF_TYPE: {
    USF: 1,
    RSF: 2,
    NIA: 3
  },

  SF_TYPE_TEXT: {
    1: 'USF',
    2: 'RSF',
    3: 'NIA'
  },

  SF_TYPE_ADJECTIVES: {
    1: 'Usable',
    2: 'Rentable',
    3: 'Net'
  },

  RSF_REGIONS: {
    DEFAULT_INTL: 'default-intl',
    DEFAULT_USA: 'default-usa',
    NYC: 'nyc',
    USA_METRO: 'usa-metro',
    USA_SMALL: 'usa-small',
    UK: 'uk',
    GE: 'ge',
    APAC: 'apac',
    FR: 'fr'
  },

  PROFILE_TYPE: {
    US_REGULAR: 'US_REGULAR',
    US_SMALL: 'US_SMALL',
    UK_REGULAR: 'UK_REGULAR',
    GERMANY_REGULAR: 'GERMANY_REGULAR',
    APAC_REGULAR: 'APAC_REGULAR',
    SPAIN_REGULAR: 'SPAIN_REGULAR',
    FRANCE_REGULAR: 'FRANCE_REGULAR',
    SLOVAKIA_REGULAR: 'SLOVAKIA_REGULAR',
    POLAND_REGULAR: 'POLAND_REGULAR',
    HUNGARY_REGULAR: 'HUNGARY_REGULAR',
    NETHERLAND_REGULAR: 'NETHERLAND_REGULAR',
    COLOMBIA_REGULAR: 'COLOMBIA_REGULAR'
  },

  MAX_LOSS_FACTOR: 50

};
