module.exports = {

  INPUT_PLACEHOLDER: '[INPUT]',

  QUESTION_TYPE: {
    PROFILE: 'profile',
    ASSUMPTION: 'assumption',
    USER_DATA: 'userData'
  },

  QUESTION_CATEGORY: {
    IDENTITY: 'Identity',
    ORGANIZATION: 'Organization',
    OFFICE: 'Office',
    DETAILS: 'Details'
  },

  PROFILE_ANSWER_TYPE: {
    PICK: 'pick',
    RANK: 'rank'
  },

  ANSWER_FORMAT: {
    SPECTRUM: 'spectrum',
    SENTENCE_COMPLETION: 'sentenceCompletion'
  },

  ANSWER_INPUT_TYPE: {
    LOCATION_AUTOCOMPLETE: 'locationAutocomplete',
    INDUSTRY_AUTOCOMPLETE: 'industryAutocomplete',
    DROPDOWN: 'dropdown'
  },

  ASSUMPTION_TYPE: {
    HEADCOUNT: 'headcount',
    COLLABORATION: 'collaboration',
    DAYS_IN_OFFICE: 'daysPerWeekInOffice',
    DENSITY: 'officeDensity'
  }

};
