const calculator   = require('./calculator');
const contact      = require('./contact');
const geography    = require('./geography');
const modal        = require('./modal');
const questionUnit = require('./questionUnit');
const quiz         = require('./quiz');
const siteLocation = require('./siteLocation');
const profileInfo  = require('./profileInfo');


module.exports = {
  calculator,
  contact,
  geography,
  modal,
  questionUnit,
  quiz,
  siteLocation,
  profileInfo
};
