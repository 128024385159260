const {
  CIRCULATION_TYPE,
  CATEGORY
} = require('../shared/spaceUnit');

const POPULATION_BLANK_TEMPLATE = {
  id: 'blank',
  name: 'Blank Template',
  templateType: 'spaceProfile',
  program: {
    me: [],
    sharedFocus: [],
    we: [],
    support: [
      {
        name: 'File Rooms',
        multiplier: 80,
        circulationType: CIRCULATION_TYPE.AMENITY,
        sf: 1,
        category: CATEGORY.SUPPORT
      },
      {
        name: '3H Drawer Lateral Files',
        multiplier: 1,
        circulationType: CIRCULATION_TYPE.AMENITY,
        sf: 1,
        category: CATEGORY.SUPPORT
      }
    ],
    amenity: [],
    assumptions: {
      sharingPopulation: 1,
      assignedSeating: true
    }
  }
};

const LOCATION_BLANK_TEMPLATE = {
  id: 'blank_location',
  name: 'Blank Template',
  templateType: 'location',
  program: {
    me: [],
    sharedFocus: [],
    we: [],
    amenity: [],
    support: []
  }
};

const COLLABORATION_BLANK_TEMPLATE = {
  id: 'blank_collaboration',
  name: 'Blank Collaboration',
  templateType: 'collaboration',
  program: {
    me: [],
    sharedFocus: [],
    we: [],
    amenity: [],
    support: [],
    assumptions: {
      collaborationRatio: 1
    }
  }
};

module.exports = {
  POPULATION_BLANK_TEMPLATE,
  LOCATION_BLANK_TEMPLATE,
  COLLABORATION_BLANK_TEMPLATE
};
