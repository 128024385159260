module.exports = {

  MOST_POPULATED_US_CITIES: [
    { city: 'Los Angeles', state: 'CA' },
    { city: 'Chicago', state: 'IL' },
    { city: 'Houston', state: 'TX' },
    { city: 'Philadelphia', state: 'PA' },
    { city: 'Phoenix', state: 'AZ' },
    { city: 'San Antonio', state: 'TX' },
    { city: 'San Diego', state: 'CA' },
    { city: 'Dallas', state: 'TX' },
    { city: 'San Jose', state: 'CA' },
    { city: 'San Gabriel', state: 'CA' },
    { city: 'Austin', state: 'TX' },
    { city: 'Jacksonville', state: 'FL' },
    { city: 'San Francisco', state: 'CA' },
    { city: 'Indianapolis', state: 'IN' },
    { city: 'Columbus', state: 'OH' },
    { city: 'Fort Worth', state: 'TX' },
    { city: 'Charlotte', state: 'VA' },
    { city: 'Detroit', state: 'MI' },
    { city: 'El Paso', state: 'TX' },
    { city: 'Seattle', state: 'WA' },
    { city: 'Denver', state: 'CO' },
    { city: 'Washington', state: 'DC' },
    { city: 'Memphis', state: 'TN' },
    { city: 'Boston', state: 'MA' },
    { city: 'Nashville', state: 'TN' },
    { city: 'Baltimore', state: 'MD' },
    { city: 'Metropolitan Government of Nashville-Davidson', state: 'TN' },
    { city: 'Oklahoma City', state: 'OK' },
    { city: 'Portland', state: 'OR' },
    { city: 'Las Vegas', state: 'NV' },
    { city: 'Milwaukee', state: 'WI' },
    { city: 'Albuquerque', state: 'NM' },
    { city: 'Tucson', state: 'AZ' },
    { city: 'Fresno', state: 'CA' },
    { city: 'Contra Costa Centre', state: 'CA' },
    { city: 'Sacramento', state: 'CA' },
    { city: 'Long Beach', state: 'CA' },
    { city: 'Kansas City', state: 'MO' },
    { city: 'Mesa', state: 'AZ' },
    { city: 'Harris', state: 'TX' },
    { city: 'Atlanta', state: 'GA' },
    { city: 'Jefferson', state: 'CO' },
    { city: 'Virginia Beach', state: 'VA' },
    { city: 'Omaha', state: 'NE' },
    { city: 'Colorado Springs', state: 'CO' },
    { city: 'Miami', state: 'FL' },
    { city: 'Oakland', state: 'CA' },
    { city: 'Minneapolis', state: 'MN' },
    { city: 'Tulsa', state: 'OK' },
    { city: 'Cleveland', state: 'OH' },
    { city: 'Wichita', state: 'KS' },
    { city: 'New Orleans', state: 'LA' },
    { city: 'Arlington', state: 'TX' },
    { city: 'Honolulu', state: 'HI' },
    { city: 'Bakersfield', state: 'CA' },
    { city: 'Tampa', state: 'FL' },
    { city: 'Anaheim', state: 'CA' },
    { city: 'Kendale Lakes', state: 'FL' },
    { city: 'Santa Ana', state: 'CA' },
    { city: 'San Gabriel', state: 'CA' },
    { city: 'Corpus Christi', state: 'TX' },
    { city: 'Riverside', state: 'NC' },
    { city: 'St Louis', state: 'MO' },
    { city: 'Lexington-Fayette', state: 'KY' },
    { city: 'West Adams', state: 'NC' },
    { city: 'Pittsburgh', state: 'PA' },
    { city: 'Stockton', state: 'CA' },
    { city: 'Anchorage', state: 'AK' },
    { city: 'Cincinnati', state: 'OH' },
    { city: 'St Paul', state: 'MN' },
    { city: 'Fayette', state: 'KY' },
    { city: 'Greensboro', state: 'NC' },
    { city: 'Toledo', state: 'OH' },
    { city: 'Newark', state: 'NJ' },
    { city: 'Ewa Beach', state: 'HI' },
    { city: 'Plano', state: 'TX' },
    { city: 'Henderson', state: 'NV' },
    { city: 'Lincoln', state: 'NE' },
    { city: 'Louisville', state: 'KY' },
    { city: 'Orlando', state: 'FL' },
    { city: 'Jersey City', state: 'NJ' },
    { city: 'Chula Vista', state: 'CA' },
    { city: 'Buffalo', state: 'NY' },
    { city: 'Fort Wayne', state: 'IN' }
  ],

  STATE_ABBREVIATION_TO_FULL: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands'
  },

  CITIES_OF_NY: ['New York', 'Brooklyn', 'Queens', 'Bronx', 'Staten Island', 'Manhattan']

};
