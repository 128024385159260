const {
  SCENARIO_TAG,
  GROWTH_TYPE
} = require('./project');
const {
  CIRCULATION_TYPE,
  CATEGORY
} = require('../shared/spaceUnit');

const PROJECT_SCENARIO_DEFAULTS = {
  DEFAULT_PLANNING_YEAR: new Date().getFullYear() + 5, // is this cheatery?
  DEFAULT_TAG: SCENARIO_TAG.PROPOSED,
  DEFAULT_GROWTH_PLAN: {
    growthType: GROWTH_TYPE.FLAT,
    rate: 0.01,
    planningYear: new Date().getFullYear() + 5
  }
};

// DEFAULTS used when creating new program parts or switching resource type
const ME_PROGRAM_DEFAULTS = {
  name: '',
  multiplier: 0,
  category: CATEGORY.ME,
  circulationType: CIRCULATION_TYPE.OPEN
};

const SHARED_FOCUS_PROGRAM_DEFAULTS = {
  name: '',
  ratio: 0,
  category: CATEGORY.SHARED_FOCUS,
  circulationType: CIRCULATION_TYPE.OPEN
};

const WE_PROGRAM_DEFAULTS = {
  name: '',
  multiplier: 0,
  category: CATEGORY.WE,
  circulationType: CIRCULATION_TYPE.ENCLOSED
};

const SUPPORT_DEFAULTS = {
  name: '',
  circulationType: CIRCULATION_TYPE.AMENITY,
  category: CATEGORY.SUPPORT
};

const AMENITY_DEFAULTS = {
  name: '',
  circulationType: CIRCULATION_TYPE.AMENITY,
  category: CATEGORY.AMENITY
};

module.exports = {
  PROJECT_SCENARIO_DEFAULTS,
  ME_PROGRAM_DEFAULTS,
  SHARED_FOCUS_PROGRAM_DEFAULTS,
  WE_PROGRAM_DEFAULTS,
  SUPPORT_DEFAULTS,
  AMENITY_DEFAULTS
};
