const alerts                          = require('./alerts');
const client                          = require('./client');
const cms                             = require('./cms');
const project                         = require('./project');
const projectDefaults                 = require('./projectDefaults');
const blankTemplatePopulationLocation = require('./blankTemplatePopulationLocation');

module.exports = {
  alerts,
  client,
  cms,
  project,
  projectDefaults,
  blankTemplatePopulationLocation
};
