const {
  REQUIRED_STRING,
  REQUIRED_NUMBER,
  OPTIONAL_NUMBER,
  OPTIONAL_STRING
}                 = require('../shared/model').MODEL_SCHEMA;
const {
  MEASUREMENT_SYSTEM
}                 = require('../shared/calculator');

const ME_TEMPLATE_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always ME

  name: OPTIONAL_STRING,
  circulationType: REQUIRED_STRING, // OPEN or ENCLOSED
  sf: REQUIRED_NUMBER,
  multiplier: REQUIRED_NUMBER // represents allocation of all ME spaces
};

const SHARED_FOCUS_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always SHARED_FOCUS

  // input by user
  name: OPTIONAL_STRING,
  circulationType: REQUIRED_STRING, // OPEN or ENCLOSED
  sf: REQUIRED_NUMBER,
  ratio: REQUIRED_NUMBER, // represents individual seats per SHARED_FOCUS space
  seats: REQUIRED_NUMBER
};

const WE_TEMPLATE_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always WE

  name: OPTIONAL_STRING,
  circulationType: REQUIRED_STRING, // OPEN or ENCLOSED
  sf: REQUIRED_NUMBER,
  seats: REQUIRED_NUMBER,
  ratio: REQUIRED_NUMBER
};

const SUPPORT_TEMPLATE_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always SUPPORT,
  isFloorDependent: { type: 'Boolean' }, // for support rows that depend on floors

  // determined by use case
  circulationType: REQUIRED_STRING, // for Spacer Pro, always AMENITY

  name: OPTIONAL_STRING,
  sf: REQUIRED_NUMBER,
  multiplier: REQUIRED_NUMBER // represents sf per person
};

const AMENITY_TEMPLATE_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always AMENITY
  circulationType: REQUIRED_STRING, // always AMENITY

  name: OPTIONAL_STRING,
  multiplier: REQUIRED_NUMBER,
  minSF: OPTIONAL_NUMBER,
  maxSF: OPTIONAL_NUMBER
};

module.exports = {

  CLIENT_SCHEMA: {
    name: { type: 'String', required: true, unique: true },
    industryCode: REQUIRED_STRING,
    // CBRE contact for the client team
    singlePointOfContact: {
      type: 'Object',
      schema: {
        firstName: REQUIRED_STRING,
        lastName: REQUIRED_STRING,
        email: REQUIRED_STRING
      }
    },
    templates: {
      type: 'Array',
      default: [],
      schema: {
        name: REQUIRED_STRING,
        id: REQUIRED_STRING,
        templateType: REQUIRED_STRING,
        unit: { type: 'String', default: MEASUREMENT_SYSTEM.IMPERIAL },
        resourceRowDisplayOrder: { type: 'Array', default: [] },
        lastUpdated: {
          type: 'Object',
          required: true,
          schema: {
            updatedDate: { type: 'Date' },
            userId: OPTIONAL_STRING
          }
        },
        program: {
          type: 'Object',
          default: {
            me: [],
            we: [],
            sharedFocus: [],
            support: [],
            amenity: []
          },
          schema: {
            me: {
              type: 'Array',
              default: [],
              schema: ME_TEMPLATE_PART_SCHEMA
            },
            sharedFocus: {
              type: 'Array',
              default: [],
              schema: SHARED_FOCUS_PART_SCHEMA
            },
            we: {
              type: 'Array',
              default: [],
              schema: WE_TEMPLATE_PART_SCHEMA
            },
            // space program can have "support" or "amenity" spaces (jointly displayed as "resources")
            // in practice, they will usually only have "support"
            support: {
              type: 'Array',
              default: [],
              schema: SUPPORT_TEMPLATE_PART_SCHEMA
            },
            amenity: {
              type: 'Array',
              default: [],
              schema: AMENITY_TEMPLATE_PART_SCHEMA
            },
            assumptions: { // location templates will have placeholder values of 1. Did this to match scenario schema
              type: 'Object',
              schema: {
                sharingPopulation: REQUIRED_NUMBER,
                staticVacancy: OPTIONAL_NUMBER,
                collaborationRatio: REQUIRED_NUMBER,
                assignedSeating: { type: 'Boolean' }
              }
            }
          }
        }
      }
    }
  }
};
