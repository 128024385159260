module.exports = {
  PAGE: {
    LANDING: 'landing',
    ABOUT: 'about',
    TERMS: 'terms',
    QUIZ: 'quiz',
    RESULTS: 'results',
    PROFILE_COMPARISON: 'profile_comparison',
    BLOG_HOME: 'blogHome',
    REFERRAL: 'referral',
    REFERRAL_RETRIEVE: 'referral_retrieve',

    // blog articles - make sure these match blog namespaces from blogCopy
    BLOG_ARTICLE_20190621: 'blogArticle20190621',
    BLOG_ARTICLE_20190625: 'blogArticle20190625',
    BLOG_ARTICLE_20190627: 'blogArticle20190627'
  }
};
