const PropTypes = require('prop-types');

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);

const textValueShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
});

const selectOptionsArray = PropTypes.arrayOf(
  PropTypes.shape({
    value: stringOrNumber.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  })
);

// from here: https://github.com/Javascript-Ninja/react-router-prop-types/blob/master/src/index.ts
const historyShape =  PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  createHref: PropTypes.func.isRequired,
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: location.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired
});

const routeMatchShape = PropTypes.shape({
  isExact: PropTypes.bool.isRequired,
  params: PropTypes.object,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

const routeLocationShape = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ])
});

const formFields = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ])
  })
);

const userShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string
});

const areaTotalsShape = PropTypes.shape({
  totalAmenitySF: PropTypes.number.isRequired,
  totalMeSF: PropTypes.number.isRequired,
  totalSF: PropTypes.number.isRequired,
  totalWeSF: PropTypes.number.isRequired
});

module.exports = {
  stringOrNumber,
  textValueShape,
  selectOptionsArray,
  routeMatchShape,
  routeLocationShape,
  historyShape,
  formFields,
  userShape,
  areaTotalsShape
};
