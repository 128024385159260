function parseJSONResponse(response) {
  return response.json().then((json) => {
    if (json.error) return Promise.reject(json.error);
    return Promise.resolve(json);
  });
}

function fetchWithCredentials(url) {
  return fetch(url, { credentials: 'include' }).then(parseJSONResponse);
}

function jsonRequestWithCredentials(method, url, data) {
  const requestOptions = {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  if (data) requestOptions.body = JSON.stringify(data);

  return fetch(url, requestOptions)
    .then(parseJSONResponse);
}

module.exports = {

  parseJSONResponse,

  fetchWithCredentials,

  jsonPostWithCredentials(url, data) {
    return jsonRequestWithCredentials('POST', url, data);
  },

  jsonDeleteWithCredentials(url) {
    return jsonRequestWithCredentials('DELETE', url);
  }

};
