module.exports = {

  MEASUREMENT_SYSTEM: {
    IMPERIAL: 'imperial',
    METRIC: 'metric',
    TSUBO: 'tsubo'
  },

  DEFAULT_UNIT: 'imperial'
};
