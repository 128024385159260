module.exports = {

  MODEL_SCHEMA: {
    OPTIONAL_STRING: { type: 'String' },
    OPTIONAL_NUMBER: { type: 'Number' },
    REQUIRED_STRING: { type: 'String', required: true },
    REQUIRED_NUMBER: { type: 'Number', required: true }
  }

};
