const {
  OPTIONAL_STRING,
  OPTIONAL_NUMBER,
  REQUIRED_STRING,
  REQUIRED_NUMBER
} = require('../shared/model').MODEL_SCHEMA;

const {
  MEASUREMENT_SYSTEM
} = require('../shared/calculator');

const ME_PROGRAM_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always ME
  label: REQUIRED_STRING, // always SEATS

  // input by user
  name: OPTIONAL_STRING,
  circulationType: REQUIRED_STRING, // OPEN or ENCLOSED
  sf: REQUIRED_NUMBER,
  multiplier: REQUIRED_NUMBER, // represents allocation of all ME spaces

  // calculated from user input
  quantity: REQUIRED_NUMBER,
  nsf: REQUIRED_NUMBER,
  usf: REQUIRED_NUMBER,

  // if user chooses to override calculations above, they can
  // input customQuantity and have customNSF and customUSF calculated accordingly
  customQuantity: OPTIONAL_NUMBER,
  customNSF: OPTIONAL_NUMBER,
  customUSF: OPTIONAL_NUMBER
};

const SHARED_FOCUS_AND_WE_PROGRAM_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // SHARED_FOCUS, WE

  // input by user
  name: OPTIONAL_STRING,
  circulationType: REQUIRED_STRING, // OPEN or ENCLOSED
  sf: REQUIRED_NUMBER,
  ratio: REQUIRED_NUMBER, // represents individual seats per SHARED_FOCUS/WE space
  seats: REQUIRED_NUMBER,

  // calculated from (or determined by) user input
  quantity: REQUIRED_NUMBER,
  nsf: REQUIRED_NUMBER,
  usf: REQUIRED_NUMBER,
  label: REQUIRED_STRING, // AREAS (if OPEN circulation) or ROOMS (if ENCLOSED circulation)

  // if user chooses to override calculations above, they can
  // input customQuantity and have customNSF and customUSF calculated accordingly
  customQuantity: OPTIONAL_NUMBER,
  customNSF: OPTIONAL_NUMBER,
  customUSF: OPTIONAL_NUMBER
};

// Program Parts
// NOTES on differences
//   - SUPPORT has customQuantity
//   - AMENITY has minSF, maxSF
//   - isFloorDependent only exists on locations
const SUPPORT_PROGRAM_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always SUPPORT
  label: REQUIRED_STRING, // always UNITS,
  isFloorDependent: { type: 'Boolean' }, // for support rows that depend on floors

  // determined by use case
  circulationType: REQUIRED_STRING, // for Spacer Pro, always AMENITY

  // input by user
  name: OPTIONAL_STRING,
  sf: REQUIRED_NUMBER,
  multiplier: REQUIRED_NUMBER, // represents sf per person

  // calculated from user input
  quantity: REQUIRED_NUMBER,
  nsf: REQUIRED_NUMBER,
  usf: REQUIRED_NUMBER,

  // if user chooses to override calculations above, they can
  // input customQuantity and have customNSF and customUSF calculated accordingly
  customQuantity: OPTIONAL_NUMBER,
  customNSF: OPTIONAL_NUMBER,
  customUSF: OPTIONAL_NUMBER
};

const AMENITY_PROGRAM_PART_SCHEMA = {
  id: REQUIRED_STRING,
  category: REQUIRED_STRING, // always AMENITY
  circulationType: REQUIRED_STRING, // always AMENITY
  label: REQUIRED_STRING, // always AREAS

  // input by user
  name: OPTIONAL_STRING,
  multiplier: REQUIRED_NUMBER, // represents sf per person
  minSF: OPTIONAL_NUMBER,
  maxSF: OPTIONAL_NUMBER,

  // calculated from user input
  quantity: REQUIRED_NUMBER, // always 1
  nsf: REQUIRED_NUMBER,
  usf: REQUIRED_NUMBER,

  // if user chooses to override calculations above, they can
  // input customSF and have customNSF and customUSF calculated accordingly
  customSF: OPTIONAL_NUMBER,
  customNSF: OPTIONAL_NUMBER,
  customUSF: OPTIONAL_NUMBER
};


// Project Properties
const PROJECT_TYPE = {
  FLOOR: 'floor',
  BUILDING: 'building',
  HEADQUARTERS: 'headquarters',
  PORTFOLIO: 'portfolio'
};

const PROJECT_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed'
};

const SCENARIO_TAG = {
  BUILT: 'built',
  PROPOSED: 'proposed',
  SELECTED: 'selected'
};

const GROWTH_TYPE = {
  FLAT: 'flat',
  INCREMENTAL: 'incremental',
  COMPOUNDING: 'compounding'
};

const SPACE_ANALYSIS_LOCATION_SCHEMA = {
  name: REQUIRED_STRING,
  meUSF: OPTIONAL_NUMBER,
  weUSF: OPTIONAL_NUMBER,
  resourcesUSF: OPTIONAL_NUMBER,
  locationUSF: OPTIONAL_NUMBER,
  seats: OPTIONAL_NUMBER,
  sharingRatio: OPTIONAL_NUMBER,
  collaborationRatio: OPTIONAL_NUMBER,
  openPercentage: OPTIONAL_NUMBER
};

const SPACE_ANALYSIS_SCHEMA = {
  locations: {
    type: 'Array',
    default: [],
    schema: SPACE_ANALYSIS_LOCATION_SCHEMA
  },
  sourceFileLink: OPTIONAL_STRING,
  lastUpdated: {
    type: 'Object',
    required: true,
    schema: {
      updatedDate: { type: 'Date' },
      userId: OPTIONAL_STRING
    }
  }
};

const PROJECT_SCHEMA = {
  // *************************************
  //  BASIC INFO
  // *************************************
  name: REQUIRED_STRING,
  projectType: REQUIRED_STRING,
  status: { type: 'String', default: PROJECT_STATUS.ACTIVE },
  clientId: REQUIRED_STRING,
  engagementManagerId: OPTIONAL_STRING,
  teamIds: { type: 'Array', default: [] },
  isConfidential: { type: 'Boolean', default: false },
  description: REQUIRED_STRING,
  contractStartsAt: { type: 'Date', required: true },
  contractEndsAt: { type: 'Date', required: true },
  accountNumber: OPTIONAL_STRING,
  externalResourceLink: OPTIONAL_STRING, // e.g., link to current Box folder
  unit: { type: 'String', default: MEASUREMENT_SYSTEM.IMPERIAL },
  address: {
    type: 'Object',
    required: true,
    schema: {
      address1: OPTIONAL_STRING,
      address2: OPTIONAL_STRING,
      cityStateCountry: REQUIRED_STRING, // populated from ArcGIS Geocoding Suggest
      zip: OPTIONAL_STRING
    }
  },
  // external contact on the client team
  contact: {
    type: 'Object',
    required: true,
    schema: {
      firstName: REQUIRED_STRING,
      lastName: REQUIRED_STRING,
      email: REQUIRED_STRING
    }
  },

  // *************************************
  //  POPULATIONS
  // *************************************
  populations: {
    type: 'Array',
    default: [],
    schema: {
      id: REQUIRED_STRING,
      name: REQUIRED_STRING,
      currentHeadcount: REQUIRED_NUMBER,
      description: OPTIONAL_STRING
    }
  },

  // *************************************
  //  SPACE ANALYSIS
  // *************************************
  spaceAnalysis: {
    type: 'Object',
    default: {
      locations: [],
      lastUpdated: {}
    },
    schema: SPACE_ANALYSIS_SCHEMA
  },

  // *************************************
  //  SCENARIOS
  // *************************************
  scenarioDisplayOrder: { type: 'Array', default: [] },
  scenarios: {
    type: 'Array',
    default: [],
    schema: {
      id: REQUIRED_STRING,
      name: REQUIRED_STRING,
      planningYear: REQUIRED_NUMBER,
      lossFactor: REQUIRED_NUMBER,
      isShared: { type: 'Boolean', default: false },
      isCompared: { type: 'Boolean', default: true },
      // there's some features brooding so that it might make sense at some point
      // to move this into programs (so it can be different across locations), but that's not needed yet
      circulationMap: {
        type: 'Object',
        schema: {
          open: OPTIONAL_NUMBER,
          enclosed: OPTIONAL_NUMBER,
          amenity: OPTIONAL_NUMBER
        }
      },

      totalHeadcount: OPTIONAL_NUMBER,
      tag: REQUIRED_STRING,

      growthPlanning: {
        type: 'Array',
        default: [],
        schema: {
          populationId: REQUIRED_STRING,
          growthType: OPTIONAL_STRING, // GROWTH_TYPE
          rate: OPTIONAL_NUMBER, // default 1%
          planningYear: OPTIONAL_NUMBER // default 5 years from current year
        }
      },

      spaceProfiles: {
        type: 'Array',
        default: [],
        schema: {
          populationId: REQUIRED_STRING,
          basedOnTemplateId: OPTIONAL_STRING,
          resourceRowDisplayOrder: { type: 'Array', default: [] },
          program: {
            type: 'Object',
            default: {
              me: [],
              we: [],
              sharedFocus: [],
              support: [],
              amentiy: []
            },
            schema: {
              me: {
                type: 'Array',
                default: [],
                schema: ME_PROGRAM_PART_SCHEMA
              },
              sharedFocus: {
                type: 'Array',
                default: [],
                schema: SHARED_FOCUS_AND_WE_PROGRAM_PART_SCHEMA
              },
              // space program can have "support" or "amenity" spaces (jointly displayed as "resources")
              // in practice, they will usually only have "support"
              support: {
                type: 'Array',
                default: [],
                schema: SUPPORT_PROGRAM_PART_SCHEMA
              },
              amenity: {
                type: 'Array',
                default: [],
                schema: AMENITY_PROGRAM_PART_SCHEMA
              },
              assumptions: {
                type: 'Object',
                schema: {
                  headcount: REQUIRED_NUMBER, // derived from growth planning for this spaceProfile's population
                  sharingPopulation: REQUIRED_NUMBER,
                  staticVacancy: OPTIONAL_NUMBER,
                  assignedSeating: { type: 'Boolean' }
                }
              }
            }
          }
        }
      },


      /* **** FOR SCENARIO: LOCATIONS **** */
      locations: {
        type: 'Array',
        default: [],
        schema: {
          id: REQUIRED_STRING,
          name: REQUIRED_STRING,
          populationIds: { type: 'Array', default: [] },
          basedOnTemplateIdResources: OPTIONAL_STRING,
          basedOnTemplateIdCollab: OPTIONAL_STRING,
          floors: OPTIONAL_NUMBER,
          resourceRowDisplayOrder: { type: 'Array', default: [] },
          assumptions: {
            type: 'Object',
            schema: {
              collaborationRatio: OPTIONAL_NUMBER,
              headcount: OPTIONAL_NUMBER,
              sharingPopulation: OPTIONAL_NUMBER,
              assignedSeating: { type: 'Boolean' }
            }
          },
          we: {
            type: 'Array',
            default: [],
            schema: SHARED_FOCUS_AND_WE_PROGRAM_PART_SCHEMA
          },
          amenity: {
            type: 'Array',
            default: [],
            schema: AMENITY_PROGRAM_PART_SCHEMA
          },
          support: {
            type: 'Array',
            default: [],
            schema: SUPPORT_PROGRAM_PART_SCHEMA
          }
        }
      }
    }
  }
};

module.exports = {
  SPACE_ANALYSIS_LOCATION_SCHEMA,
  SPACE_ANALYSIS_SCHEMA,
  PROJECT_TYPE,
  PROJECT_STATUS,
  PROJECT_SCHEMA,
  SCENARIO_TAG,
  GROWTH_TYPE
};
