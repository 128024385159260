module.exports = {

  userTypeQuestionId: '12',

  currentLocationQuestionId: '18',

  currentLocationQuestionIndex: 16,

  officeLocationQuestionId: '16'
};
